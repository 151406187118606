import React from 'react';
import { graphql } from 'gatsby';
import loadable from '@loadable/component';

import { QueryFragments } from '@querys/queryFragments'; // eslint-disable-line

// Components
import Layout from '@components/layout';
import ModuleZone from '~moduleZone';
import { PageMeta } from '@components/pageMeta';

const VenueBanner = loadable(() =>
  import('../components/portals/venues/venueBanner'),
);
const FeaturedLinks = loadable(() =>
  import('../components/pageModules/modules/featuredLinks'),
);
const IntroText = loadable(() =>
  import('../components/portals/venues/introText'),
);

const VenueTemplate = ({ data: { page } }) => {
  const { seo, pageModules, title } = page || {};

  return (
    <Layout config={{ reverseHeader: true }}>
      <PageMeta {...seo} />
      <VenueBanner {...page} />
      <IntroText {...page} />
      {pageModules && <ModuleZone {...pageModules} title={title} evenCols />}
      <FeaturedLinks className='pt-space-m pb-space-m' reversed />
    </Layout>
  );
};

export default VenueTemplate;

export const simpleVenueQuery = graphql`
  query standardVenueQuery($slug: String!) {
    page: sanityVenue(slug: { current: { eq: $slug } }) {
      seo {
        ...SEO
      }
      # config:pageConfig {
      #   reverseHeader
      #   unpinnedHeader
      # }
      title
      featuredImage {
        ...ImageWithPreview
      }
      excerpt
      location
      address
      addressLink
      content: _rawContent(resolveReferences: { maxDepth: 10 })
      pageModules: modules {
        ...VenueModules
      }
    }
  }
`;
